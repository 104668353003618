/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {MapContainer, Marker} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L, {Map} from 'leaflet';
import {powerBankCoordinatesList} from '../../utils/data';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {useNavigate} from 'react-router-dom';
import {mapOptions} from '../../services/MapConfig';
import {
  PiCrosshair,
  PiList,
  PiMagnifyingGlass,
  PiQuestionMark,
} from 'react-icons/pi';
import {locationIcon, reviveMarkerIcon, scanner} from '../../assets/images';
import {color} from '../../assets/colors';

type MarkerDataProps = {
  type: string;
  type_icon: string;
  lat: number;
  lng: number;
  place: string;
  address: string;
  online_image: any;
  offline_image: any;
  image: any;
  loanable: number;
  returnable: number;
  distance: number;
  distance_unit: string;
  open: boolean;
  closed_until: string;
  timing: string;
  station_status: boolean;
  price: number;
  currency: string;
  time_duration_in_mins: number;
};

type PositionProps = {
  lat: number;
  lng: number;
  latitudeDelta: number;
  longitudeDelta: number;
};

const Home: React.FC = () => {
  const centerPosition: [number, number] = [52.3725, 4.8872];
  const navigate = useNavigate();
  const markerPlace = {
    type: '',
    type_icon: '',
    lat: 44.6455755192,
    lng: -1.25011620165,
    place: '',
    address: '',
    online_image: '',
    offline_image: '',
    image: '',
    loanable: 0,
    returnable: 0,
    distance: 0,
    distance_unit: '',
    open: false,
    closed_until: '',
    timing: '',
    station_status: false,
    price: 0,
    currency: '',
    time_duration_in_mins: 0,
  };

  const initialPosition = {
    lat: 52.367845617427975,
    lng: 4.902928148300927,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  const defaultIcon = L.icon({
    iconUrl: reviveMarkerIcon,
    iconSize: [36, 40],
    iconAnchor: [15, 44],
    popupAnchor: [1, -44],
    shadowSize: [40, 40],
  });

  const currentLocationIcon = L.icon({
    iconUrl: locationIcon,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -44],
    shadowSize: [40, 40],
  });

  const DEFAULT_ZOOM_LEVEL = 13;
  const MAX_ZOOM_LEVEL = 23; //20
  const MIN_ZOOM_LEVEL = 3;

  const mapRef = useRef<Map | null>(null);

  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false);
  const [selectedMarkerPlace, setSelectedMarkerPlace] =
    useState<MarkerDataProps>(markerPlace);
  const [currentPosition, setCurrentPosition] =
    useState<PositionProps>(initialPosition);

  const [clusterRadius, setClusterRadius] = useState<number>(40); // default radius
  const [showAlert, setShowAlert] = useState<boolean>(false);

  // Custom clustering options
  const clusterOptions = {
    chunkedLoading: true,
    removeOutsideVisibleBounds: true,
    maxClusterRadius: clusterRadius, // Dynamically set the cluster radius
  };

  const toggleBottomSheetOpen = () => setBottomSheetOpen(true);

  const toggleBottomSheetClose = () => setBottomSheetOpen(false);

  const handleMarkerPlaceClick = (markerItem: typeof markerPlace) => {
    setSelectedMarkerPlace(markerItem);
    toggleBottomSheetOpen();
  };

  const createClusterCustomIcon = function (cluster: any) {
    return L.divIcon({
      className: 'custom-cluster-icon',
      html: `
        <div class="custom-cluster-icon-inner">
          ${cluster.getChildCount()}
        </div>`,
      iconSize: L.point(40, 40, true), // Set the size of the icon
      iconAnchor: L.point(20, 20, true), // Anchor the icon at the center
    });
  };

  const getCurrentLocation = () => {
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition?.(
        position => {
          const newPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          };
          setCurrentPosition(newPosition);
          mapRef.current?.flyTo(
            newPosition,
            DEFAULT_ZOOM_LEVEL + 2, // Adjust zoom level as needed
            {
              animate: true,
              duration: 0.5, // Adjust animation duration for smoothness
              easeLinearity: 0.3, // Controls animation easing
            },
          );
        },
        error => {
          console.error('Error getting the current position:', error);
          setShowAlert(true);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setShowAlert(true); // Handle case where geolocation is not supported
    }
  };

  const Alert = ({onClose}: {onClose: () => void}) => {
    return (
      <div style={overlayStyle}>
        <div style={alertDivStyle}>
          <div style={alertStyle}>
            <span style={errorText}>{'error'}</span>
            <span style={errorDetailText}>
              {'Error: The Geolocation service failed.'}
            </span>
          </div>
          <div onClick={onClose} style={buttonDivStyle}>
            <button style={buttonStyles}>{'Sure'}</button>
          </div>
        </div>
      </div>
    );
  };

  // Detect screen width and adjust cluster radius accordingly
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 480) {
        setClusterRadius(20); // Mobile devices //50
      } else if (width <= 768) {
        setClusterRadius(30); // Tablets  //60
      } else if (width <= 1024) {
        setClusterRadius(40); // Laptops or small screens //70
      } else {
        setClusterRadius(50); // Desktops or large screens  //80
      }
    };

    // Call the function initially and also set a listener for window resize events
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Detect current location with permission alert
  useEffect(() => {
    getCurrentLocation();
  }, []);

  return (
    <div style={mainDiv}>
      <div className="ui-elements px-4 absolute top-6 w-full z-10">
        <div className="flex items-center justify-between gap-4">
          <button className="btn-emerald w-[50px] h-[50px] flex items-center justify-center border-black/20 border">
            <PiList size={32} />
          </button>
          <button className="btn-emerald w-[50px] h-[50px] flex items-center justify-center border-black/20 border">
            <PiQuestionMark size={32} />
          </button>
        </div>
      </div>

      <MapContainer
        ref={mapRef}
        zoom={MAX_ZOOM_LEVEL - DEFAULT_ZOOM_LEVEL}
        maxZoom={MAX_ZOOM_LEVEL}
        minZoom={MIN_ZOOM_LEVEL}
        zoomControl={false}
        center={centerPosition}
        style={{height: '100vh', width: '100%'}}
        markerZoomAnimation
        zoomAnimation
        zoomAnimationThreshold={4}
        preferCanvas>
        {currentPosition.lat !== initialPosition.lat &&
        currentPosition.lng !== initialPosition.lng ? (
          <Marker
            icon={currentLocationIcon}
            position={currentPosition}></Marker>
        ) : null}

        <MarkerClusterGroup
          {...clusterOptions}
          onClick={(e: any) => console.log('onClick', e)}
          iconCreateFunction={createClusterCustomIcon}
          spiderfyOnMaxZoom={true}
          polygonOptions={{
            fillColor: color.white,
            color: color.blue_dianne,
            weight: 1,
            opacity: 0.4,
            fillOpacity: 0.8,
          }}
          showCoverageOnHover={true}>
          {powerBankCoordinatesList.map((marker, index) => {
            return marker.markersData.map((markerItem, markerIndex) => {
              return (
                <Marker
                  key={`${index}-${markerIndex}`}
                  position={[markerItem.lat, markerItem.lng]}
                  icon={defaultIcon}></Marker>
              );
            });
          })}
        </MarkerClusterGroup>

        <ReactLeafletGoogleLayer
          apiKey={mapOptions.googleMapApiKey}
          styles={mapOptions.reactLeafletGoogleLayerTheme}
        />
      </MapContainer>

      <div className="ui-elements-bar px-4 sm:max-w-[623px] max-w-[382px] mx-auto fixed w-full left-0 right-0 sm:bottom-[60px] bottom-10">
        <div className="scan-qr flex justify-between items-center w-full p-2">
          <button className="btn-dark-blue flex flex-none justify-center items-center md:w-[67px] w-[57px] md:h-[67px] h-[57px]">
            <PiMagnifyingGlass size={30} className="text-emerald-500" />
          </button>
          <p className="font-semibold text-base md:text-xl lg:text-2xl sm:mt-10 mt-[35px]">
            Scan QR to start rent
          </p>
          <button
            onClick={getCurrentLocation}
            className="btn-dark-blue flex flex-none justify-center items-center md:w-[67px] w-[57px] md:h-[67px] h-[57px]">
            <PiCrosshair size={32} className="text-emerald-500" />
          </button>
        </div>
        <div className="absolute -top-4 sm:-top-5 left-1/2 -translate-x-2/4 -translate-y-2/4 h-fit">
          <button
            onClick={() => navigate('/type-code')}
            className="btn-dark-blue w-[86px] h-[86px] sm:w-[102px] sm:h-[102px] rounded-full flex items-center justify-center mx-auto">
            <img src={scanner} alt="scanner" />
          </button>
        </div>
      </div>

      {showAlert && (
        <Alert
          onClose={() => {
            setShowAlert(false);
          }}
        />
      )}
    </div>
  );
};

const mainDiv: CSSProperties = {
  height: '100vh',
  width: '100%',
  position: 'relative',
};

const overlayStyle: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const alertDivStyle: CSSProperties = {
  backgroundColor: 'white',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '18.5%',
  height: '24%',
  borderRadius: '10px',
  textAlign: 'center',
  display: 'flex',
};

const alertStyle: CSSProperties = {
  padding: '15px 0px 60px 0px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  display: 'flex',
};

const errorText: CSSProperties = {
  color: color.black,
  fontWeight: 'revert',
  fontFamily: 'serif',
  fontSize: '22px',
};

const errorDetailText: CSSProperties = {
  color: color.black,
  marginTop: '34px',
  fontWeight: 'revert',
  fontFamily: 'serif',
  fontSize: '20px',
};

const buttonDivStyle: CSSProperties = {
  borderTop: `1px solid ${color.mischka}`,
  height: '100%',
  width: '100%',
  overflow: 'hidden',
};

const buttonStyles: CSSProperties = {
  marginTop: '10px',
  padding: '5px 10px',
  borderRadius: '3px',
  color: color.pastel_green,
  cursor: 'pointer',
};

export default Home;
