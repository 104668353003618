/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
} from 'react-router-dom';
import {
  Home,
  Login,
  Profile,
  Otp,
  StationDetail,
  TypeCode,
  // Scanner,
} from './pages';
import {AuthProvider} from './context/AuthContext';
import Checkout from './pages/Checkout';
import {PrivateRoute} from './components';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/login" element={<Login />} />
          {/* <PrivateRoute key="/profile" element={<Profile />} /> */}
          <Route
            path="/profile"
            element={<PrivateRoute element={<Profile />} />}
          />
          <Route path="/station-detail" element={<StationDetail />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/type-code" element={<TypeCode />} />
          {/* <Route path="/scanner" element={<Scanner />} /> */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
