import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/high-res.css';
import {apple, email, google, loginBanner} from '../../assets/images/index';
import {Link} from 'react-router-dom';

const Login: React.FC = () => {
  const [phone, setPhone] = useState('');
  return (
    <div className="max-w-[484px] mx-auto sm:px-0 px-6 py-6 min-h-screen flex md:items-center">
      <div>
        <img className="mb-6 w-full" alt="login-banner" src={loginBanner} />
        <label className="block font-medium mb-4 text-xl">
          Enter your mobile number
        </label>
        <div className="phone-select mb-6">
          <PhoneInput
            enableSearch={true}
            country={'nl'}
            value={phone}
            onChange={setPhone}
          />
        </div>
        <button className="btn btn-primary px-8 mb-6 font-semibold">
          Continue
        </button>
        <div className="text-gray-100 mb-6 relative block w-full text-center font-medium before:block before:relative before:w-full before:top-[14px] before:z-0 before:bg-gray-100 before:h-[1px] px-12">
          <span className="px-5 relative bg-white">or</span>
        </div>
        <button className="btn btn-secondary font-bold px-4 mb-6 flex gap-4 justify-center">
          <img alt="google-icon" src={google} />
          Continue with Google
        </button>
        <button className="btn btn-secondary font-bold px-4 mb-6 flex gap-4 justify-center">
          <img alt="google-icon" src={apple} />
          Continue with Apple
        </button>
        <button className="btn btn-secondary font-bold px-4 mb-6 flex gap-4 justify-center">
          <img alt="google-icon" src={email} />
          Continue with Email
        </button>
        <p className="mb-6 text-sm">
          By proceeding, you give Revive and its partners permission to call the
          provided number or send WhatsApp messages or SMS messages (for
          example, via an automated service).
        </p>
        <p className="text-sm">
          This site is protected by reCAPTCHA and the Google{' '}
          <Link
            className="font-semibold underline"
            target={'_blank'}
            to="https://revivesharing.com/privacy-policy#privacyPolicy">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            className="font-semibold underline"
            target={'_blank'}
            to="https://revivesharing.com/terms-conditions#termsConditions">
            Terms of Service
          </Link>{' '}
          apply.
        </p>
      </div>
    </div>
  );
};

export default Login;
