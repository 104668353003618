import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {useAuth} from '../../context/AuthContext';

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({element, ...rest}) => {
  const {isAuthenticated} = useAuth();

  return (
    <Route
      {...rest}
      element={isAuthenticated ? element : <Navigate to="/login" replace />}
    />
  );
};

export default PrivateRoute;
