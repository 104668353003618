/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {IDetectedBarcode} from '@yudiel/react-qr-scanner';
import {letterT, scanCode, scannerBlack} from '../../assets/images/index';
import {QrScanner} from '../../components';

const Scanner = () => {
  const [otp, setOtp] = useState('');
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [hasCamera, setHasCamera] = useState<boolean>(false);

  const handleScan = (detectedCodes: IDetectedBarcode[]) => {
    console.log('QR Code Data:', detectedCodes[0].rawValue);
  };

  const handleError = (error: any) => {
    console.error('QR Scan Error:', error);
  };

  const checkCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter(
        device => device.kind === 'videoinput',
      );

      if (videoInputDevices.length === 0) {
        setHasCamera(false);
        setIsScannerOpen(false);
        alert('There is no camera on this device');
      } else {
        setHasCamera(true);
        setIsScannerOpen(true);
      }
    } catch (err) {
      console.error('Error checking camera devices:', err);
      setHasCamera(false);
      setIsScannerOpen(false);
      alert('Unable to access camera devices.');
    }
  };

  const goToScanner = () => {
    if (!isScannerOpen) {
      checkCamera();
    }
  };

  return (
    <>
      <div className="container">
        <div className="xl:mt-[100px] mt-6 mb-[30px]">
          <Link
            to="#"
            className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-md w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
            <SlArrowLeft />
          </Link>
        </div>
        <div className="max-w-[484px] mx-auto">
          <div className="flex justify-center sm:gap-4 gap-3 w-full mb-11">
            <Link
              to="/scanner"
              className="btn btn-primary font-bold sm:px-[34px] px-[30px] w-max flex gap-2.5 justify-center items-center">
              <img alt="Scanner icon" src={scannerBlack} />
              Scan
            </Link>
            <Link
              to="/type-code"
              className="btn btn-secondary font-bold sm:px-[39px] px-[30px] w-max flex gap-2.5 justify-center items-center">
              <img alt="Scanner icon" src={letterT} />
              Type in
            </Link>
          </div>
          <div className="flex gap-4 md:mb-[60px] mb-[30px]">
            <div>
              <div className="bg-black/15 p-2.5 w-max rounded-lg mb-2.5">
                <img
                  alt="Scan Code"
                  src={scanCode}
                  className="sm:w-[50px] w-7"
                />
              </div>
              <p className="sm:text-xl text-sm">123456</p>

              <QrScanner onScan={handleScan} onError={handleError} />
            </div>
            <p className="sm:mt-2.5">
              Scan the code on the front of the Revive station
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scanner;
