import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';

const Settings: React.FC = () => {
  return (
    <div className="min-h-screen">
      <div className="bg-white xl:pt-[100px] sm:pt-[64px] pt-6 pb-[15px] mb-[15px] sticky top-0">
        <div className="container">
          <div className="flex items-center gap-[30px]">
            <Link
              to="/"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl font-semibold">Settings</h4>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          
        </div>
      </div>
    </div>
  );
};

export default Settings;
