import React from 'react';
import classNames from 'classnames';

interface OrderCardProps {
  orderNo: string;
  venueRented: string;
  venueReturned: string;
  date: string;
  time: string;
  buttonText: string;
}

const OrderCard: React.FC<OrderCardProps> = ({ orderNo, venueRented, venueReturned, date, time, buttonText }) => {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center">
          <span className="text-gray-700">Order No.</span>
          <span className="font-bold">€00.00</span>
        </div>
        <p className="text-gray-500 text-sm mb-2">{orderNo}</p>
        <p className="text-sm mb-2">
          <span className="font-bold">⚡ Venue Rented:</span> {venueRented}
        </p>
        <p className="text-sm mb-4">
          <span className="font-bold">🔄 Venue Returned:</span> {venueReturned}
        </p>
        <div className="flex justify-between items-center">
          <p className="text-gray-600 text-sm">{date}</p>
          <p className="text-gray-600 text-sm">{time}</p>
        </div>
        <button
          className={classNames(
            'mt-4 text-white font-bold py-2 px-4 rounded', 
            {
              'bg-yellow-400': buttonText === 'Rented',
              'bg-gray-600': buttonText !== 'Rented',
            }
          )}
        >
          {buttonText}
        </button>
      </div>
    );
};

export default OrderCard;
