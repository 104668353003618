/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css';
import {PiCaretLeft, PiCaretRight} from 'react-icons/pi';
// import {Link} from 'react-router-dom';
import {otpBanner} from '../../assets/images/index';
import { Link } from 'react-router-dom';

const Otp: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [isNextEnable, setIsNextEnable] = useState(false);
  return (
    <div className='container'>
      <div className="max-w-[484px] mx-auto py-6 min-h-screen flex flex-col items-center md:justify-center">
        <div>
          <img className="mb-6 w-full" alt="login-banner" src={otpBanner} />
          <div className="flex items-center gap-6 mb-6">
            <h4 className="text-2xl">Revive account</h4>
          </div>
          <p className="mb-6">
            Enter the 4-digit code that was sent to you via 06 24247435
          </p>
          <OtpInput
            value={otp}
            onChange={otpVal => {
              setOtp(otpVal);
              setIsNextEnable(otpVal.length === 4);
            }}
            numInputs={4}
            containerStyle={{gap: '24px'}}
            renderInput={(props: any) => (
              <input
                {...props}
                placeholder="0"
                className="p-2 border border-gray-medium text-gray-950 text-xl font-medium rounded-lg !w-[53px] h-14 flex items-center justify-center bg-[#F0F0F0] placeholder:text-gray-100 focus:border-[#FFBC07] focus-visible:outline-[#FFBC07] shadow focus:placeholder-transparent"
              />
            )}
          />
          <button className="btn-gray mt-6 font-medium px-5">
            I did not receive a code
          </button>
        </div>
        <div className="flex items-center gap-3 justify-between w-full pt-[163px]">
          <Link to="/login" className="btn-gray mt-6 font-medium w-[50px] h-[50px] flex items-center justify-center">
            <PiCaretLeft size={20} />
          </Link>
          {/* 
            input fill thay ne tyare remove class disabled and remove attribute disabled
          */}
          <button
            disabled={!isNextEnable}
            className={`btn-gray mt-6 font-medium px-5 flex items-center gap-2.5 ${
              isNextEnable ? '' : 'disabled'
            }`}>
            Next <PiCaretRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Otp;
