import React, {useState} from 'react';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {letterT, scanCode, scanCodeWhite, scannerBlack} from '../../assets/images/index';
import {IDetectedBarcode} from '@yudiel/react-qr-scanner';
import {QrScanner} from '../../components';

const TypeCode = () => {
  const [otp, setOtp] = useState('');

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['Scan', 'Type in'];

  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [hasCamera, setHasCamera] = useState<boolean>(false);

  const handleScan = (detectedCodes: IDetectedBarcode[]) => {
    console.log('QR Code Data:', detectedCodes[0].rawValue);
  };

  const handleError = (error: any) => {
    console.error('QR Scan Error:', error);
  };

  const checkCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter(
        device => device.kind === 'videoinput',
      );

      if (videoInputDevices.length === 0) {
        setHasCamera(false);
        setIsScannerOpen(false);
        alert('There is no camera on this device');
      } else {
        setHasCamera(true);
        setIsScannerOpen(true);
      }
    } catch (err) {
      console.error('Error checking camera devices:', err);
      setHasCamera(false);
      setIsScannerOpen(false);
      alert('Unable to access camera devices.');
    }
  };

  const goToScanner = () => {
    if (!isScannerOpen) {
      checkCamera();
    }
  };

  return (
    <>
      <div className="container">
        <div className="xl:mt-[100px] mt-6 mb-[30px] relative z-[99999]">
          <Link
            to="/"
            className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-md w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
            <SlArrowLeft />
          </Link>
        </div>
        <div className="max-w-[484px] mx-auto">
          <div className="flex justify-center sm:gap-4 gap-3 w-full mb-11 relative z-[99999]">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`btn font-bold sm:px-[34px] px-[30px] w-max flex gap-2.5 justify-center items-center ${
                  activeTab === index
                    ? 'btn-primary'
                    : 'btn-secondary'
                }`}
                onClick={() => setActiveTab(index)}>
                <img alt="Scanner icon" src={index == 0 ? scannerBlack : letterT } />
                {tab}
              </button>
            ))}
          </div>
          {activeTab === 0 && 
            <>
              <div className="flex gap-4 md:mb-[60px] mb-[30px] relative z-[99999]">
                <div>
                  <div className="bg-white/15 p-2.5 w-max rounded-lg mb-2.5">
                    <img
                      alt="Scan Code"
                      src={scanCodeWhite}
                      className="sm:w-[50px] w-7"
                    />
                  </div>
                  <p className="sm:text-xl text-sm text-white">123456</p>

                </div>
                <p className="sm:mt-2.5 text-white">
                  Scan the code on the front of the Revive station
                </p>
              </div>
              <div>
                <QrScanner onScan={handleScan} onError={handleError} />
              </div>
            </>
          }
          {activeTab === 1 &&
            <>
              <div className="flex gap-4 md:mb-[60px] mb-[30px]">
                <div>
                  <div className="bg-black/15 p-2.5 w-max rounded-lg mb-2.5">
                    <img
                      alt="Scan Code"
                      src={scanCode}
                      className="sm:w-[50px] w-7"
                    />
                  </div>
                  <p className="sm:text-xl text-sm">123456</p>
                </div>
                <p className="sm:mt-2.5">
                  Type in the number displayed under the station code
                </p>
              </div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle={{justifyContent: 'space-between'}}
                renderInput={(props: any) => (
                  <input
                    {...props}
                    placeholder="0"
                    className="p-2 border border-[#D9D9D9] text-gray-950 text-xl font-medium rounded-lg sm:!w-[53px] !w-[43px] h-14 flex items-center justify-center bg-[#F0F0F0] placeholder:text-gray-100 focus:border-[#FFBC07] focus-visible:outline-[#FFBC07] shadow-[5px_5px_20px_0px_rgba(0,0,0,0.06)] focus:placeholder-transparent"
                  />
                )}
              />
            </>
          } 
        </div>
      </div>
    </>
  );
};

export default TypeCode;
