/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {CSSProperties, useState} from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {PiArrowCounterClockwise, PiTreeStructure} from 'react-icons/pi';
import {noneRotateLeft, reviveStationImg} from '../../assets/images/index';
import {color} from '../../assets/colors';

const StationDetail: React.FC = () => {
  const [isCheckout, setIsCheckout] = useState<boolean>(false);

  const showCheckout = () => setIsCheckout(true);

  const hideCheckout = () => setIsCheckout(false);

  return (
    <div className="min-h-screen flex flex-col pt-6 lg:py-[100px]">
      <div className="container lg:mb-0 pb-[115px] lg:pb-0">
        <div className="grid grid-cols-12 lg:gap-x-10 gap-y-[30px] lg:border-0 border border-[#D6D6D6] rounded-[20px] mb-8 lg:mb-0">
          <div className="lg:col-span-6 col-span-12 relative">
            <div className="flex align-middle gap-4 absolute top-4 lg:top-5 left-4 lg:left-5 right-4 lg:right-5">
              <Link
                to="#"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-md w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <span className="ml-auto font-semibold leading-7 py-[10px] px-4 bg-amber-600 text-gray-950 rounded-[12px]">
                <span className="text-2xl">€2</span> / half hour
              </span>
            </div>
            <img
              className="w-full rounded-[20px] lg:object-left-bottom object-center object-cover lg:h-[450px] xl:h-[500px] 2xl:h-[682px] h-[230px]"
              src={reviveStationImg}
              alt="ReviveStation Img"
            />
          </div>

          <div className="lg:col-span-6 col-span-12 flex flex-col lg:px-0 px-4 lg:pt-[30px]">
            <h1 className="text-2xl font-semibold">Boules Bites Bar</h1>
            <p className="mb-6 font-medium text-sm">
              Buikslotermeerplein 2009, Amsterdam...
            </p>
            <p className="mb-5 font-medium text-sm">
              Free of charge for the first 1 mins. A Maximum daily charge of
              €12. Deposit will be automatically deducted we rent reaches €10
            </p>
            <p className="mb-5 font-medium text-sm flex mt gap-[10px] items-center">
              <PiTreeStructure size={18} /> Adapted to all types of mobile phone{' '}
            </p>
            <p className="mb-5 font-medium text-sm flex mt gap-[10px] items-center">
              {' '}
              <PiArrowCounterClockwise size={18} className="rotate-[40deg]" />
              Can be returned to any other site
            </p>
            <p className="mb-5 font-medium text-sm flex mt gap-[10px] items-start">
              <img
                className="mt-1"
                src={noneRotateLeft}
                alt="Arrow Rotate Left Img"
              />{' '}
              Non-return fee: in case you do not return the power bank within 7
              days we will direct debit €50,- from your bank account
            </p>
            <button
              onClick={showCheckout}
              className="btn btn-primary px-8 font-semibold lg:mt-auto mt-[87px] hidden lg:inline-block">
              <span className="me-3">Pre-authorization</span> 10.00 EUR{' '}
            </button>
          </div>
        </div>
      </div>

      <div className="py-7 fixed bottom-0 w-full bg-white lg:hidden inline-block shadow-3xl">
        <div className="container">
          <button
            onClick={showCheckout}
            className="btn btn-primary px-4 font-semibold">
            <span className="me-3">Pre-authorization</span> 10.00 EUR{' '}
          </button>
        </div>
      </div>

      {isCheckout ? (
        <div style={iframeDiv} onClick={hideCheckout}>
          <iframe
            src="/checkout"
            width="400"
            height="600"
            title="Checkout"
            style={iframe}
          />
        </div>
      ) : null}
    </div>
  );
};

const iframeDiv: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  height: '100vh',
  width: '100%',
  zIndex: 9999,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
};

const iframe: CSSProperties = {
  border: 'none',
  borderRadius: 15,
  backgroundColor: color.white,
  marginBottom: 100,
};

export default StationDetail;
