/* eslint-disable @typescript-eslint/no-unused-vars */
import {CSSProperties, FormEvent, FunctionComponent, useState} from 'react';

import {Elements, useElements} from '@stripe/react-stripe-js';
import {useStripe, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';

import {color, rgbaColor} from '../../assets/colors';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

const Checkout: FunctionComponent = () => {
  const options: StripeElementsOptions = {
    // passing the stripe elements options
    mode: 'payment',
    amount: 1099,
    currency: 'eur',
    // Fully customizable with appearance API.
    appearance: {
      theme: 'flat',
      labels: 'floating',
      variables: {
        colorPrimary: '#0A74DA',
        colorBackground: '#F4F4F5',
        colorText: '#333',
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

const CheckoutForm: FunctionComponent = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isPaymentElementComplete, setIsPaymentElementComplete] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string | any>('');

  const disabled = true;

  const bgColor = rgbaColor.cornflower_blue_0_5;

  const handlePaymentElementChange = (event: any) =>
    setIsPaymentElementComplete(event.complete);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true); // Set loading state

    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setMessage('Payment status: ' + paymentIntent.status + '🎉');
    } else {
      setMessage('Unexpected state!');
    }

    setTimeout(() => {
      setIsProcessing(false);
      setIsSuccess(true); // Show success
    }, 2000);
  };

  return (
    <form onSubmit={handleSubmit} style={form}>
      <PaymentElement
        id="payment-element"
        onChange={handlePaymentElementChange}
      />

      <button
        type="submit"
        disabled={disabled} // Disable when processing or success
        style={{
          ...payButton,
          backgroundColor: bgColor,
        }}>
        {isSuccess ? (
          <span className="check-icon">&#10003;</span>
        ) : isProcessing ? (
          'Processing...'
        ) : (
          'Pay'
        )}
      </button>

      <span style={messageStyle}>{message}</span>
    </form>
  );
};

const form: CSSProperties = {padding: '10px 20px 10px 20px'};

const payButton: CSSProperties = {
  backgroundColor: color.dodger_blue,
  borderRadius: 10,
  padding: '10px 20px 10px 20px',
  color: color.white,
  fontWeight: 'normal',
  marginTop: 15,
  width: '100%',
  cursor: 'pointer',
  opacity: 0.9, // Full opacity
  transition: 'background-color 0.3s ease',
};

const messageStyle: CSSProperties = {
  color: color.black,
  fontWeight: 'normal',
  fontFamily: 'serif',
  fontSize: '16px',
};

export default Checkout;
