import React, {CSSProperties} from 'react';
import {IDetectedBarcode, Scanner as QRScanner} from '@yudiel/react-qr-scanner';
import './QrScanner.scss';

type scannerProps = {
  onScan: (detectedCodes: IDetectedBarcode[]) => void;
  onError?: (error: unknown) => void;
};

const QrScanner = (props: scannerProps) => {
  const {onError, onScan} = props;

  const components = {
    torch: false,
    finder: false,
  };

  return (
    <QRScanner
      onError={onError}
      onScan={onScan}
      styles={{
        container: containerStyle,
        finderBorder: 30,
        video: videoStyle,
      }}
      components={components}>
      <div className="qr-scanner qrcode-stream-overlay">
        <div>
          <div className="qr-scanner">
            <div className="box">
              <div className="line"></div>
              <div className="angle"></div>
            </div>
          </div>
        </div>
      </div>
    </QRScanner>
  );
};

const containerStyle: CSSProperties = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const videoStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  objectFit: 'cover',
};

export default QrScanner;
