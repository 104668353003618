/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {CSSProperties, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {PiArrowCounterClockwise, PiTreeStructure} from 'react-icons/pi';
import {noneRotateLeft, reviveStationImg} from '../../assets/images/index';

const StationDetail: React.FC = () => {
  const navigate = useNavigate();

  const [isCheckout, setIsCheckout] = useState<boolean>(false);

  const navigateToChekout = () => navigate('/checkout');

  const showCheckout = () => setIsCheckout(true);

  const hideCheckout = () => setIsCheckout(false);

  return (
    <div className="min-h-screen">
      <div className='xl:pt-[100px] sm:pt-[64px] pt-6 xl:pb-6 pt-[114px]'>
        <div className="container">
          <div className="grid grid-cols-12 xl:gap-x-10 gap-y-[30px] xl:border-0 border border-[#D6D6D6] rounded-[20px] mb-8 xl:mb-0">
            <div className="xl:col-span-6 col-span-12 relative">
              <div className="flex align-middle gap-4 absolute top-4 xl:top-5 left-4 xl:left-5 right-4 xl:right-5">
                <Link
                  to="/"
                  className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                  <SlArrowLeft />
                </Link>
                <span className="ml-auto font-semibold leading-7 py-[10px] px-4 bg-amber-600 text-gray-950 rounded-[12px]">
                  <span className="text-2xl">€2</span> / half hour
                </span>
              </div>
              <img
                className="w-full rounded-[20px] xl:object-left-bottom object-center object-cover xl:h-[450px] xl:h-[500px] 2xl:h-[682px] h-[230px]"
                src={reviveStationImg}
                alt="ReviveStation Img"
              />
            </div>

            <div className="xl:col-span-6 col-span-12 flex flex-col xl:px-0 px-4 xl:pt-[30px]">
              <h1 className="text-2xl">Boules Bites Bar</h1>
              <p className="mb-6">
                Buikslotermeerplein 2009, Amsterdam...
              </p>
              <p className="mb-5">
                Free of charge for the first 1 mins. A Maximum daily charge of
                €12. Deposit will be automatically deducted we rent reaches €10
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                <PiTreeStructure size={18} className='flex-none' /> Adapted to all types of mobile phone{' '}
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                {' '}
                <PiArrowCounterClockwise size={18} className="rotate-[40deg] flex-none" />
                Can be returned to any other site
              </p>
              <p className="mb-5 flex gap-[10px] items-start">
                <img
                  className="mt-1 flex-none"
                  src={noneRotateLeft}
                  alt="Arrow Rotate Left Img"
                />{' '}
                Non-return fee: in case you do not return the power bank within 7
                days we will direct debit €50,- from your bank account
              </p>
              <button
                onClick={navigateToChekout}
                className="btn btn-primary px-8 mt-auto mt-[87px] hidden xl:block">
                <span className="me-3">Pre-authorization</span> 10.00 EUR{' '}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="py-7 fixed bottom-0 w-full bg-white xl:hidden inline-block shadow-3xl">
        <div className="container">
          <button
            onClick={navigateToChekout}
            className="btn btn-primary px-4">
            <span className="me-3">Pre-authorization</span> 10.00 EUR{' '}
          </button>
        </div>
      </div>

      {/* {isCheckout ? (
        <div style={iframeDiv} onClick={hideCheckout}>
          <iframe src="/checkout" title="Checkout" style={iframe} />
        </div>
      ) : null} */}
    </div>
  );
};

const iframeDiv: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  height: '100%',
  width: '100%',
  zIndex: 9999,
  backgroundColor: 'rgba(0,0,0,0.8)',
};

const iframe: CSSProperties = {
  border: 'none',
  borderRadius: 15,
  height: '100%',
  maxHeight: '100vh',
  width: '100%',
  maxWidth: '324px',
};

export default StationDetail;
