export const color = {
  alabaster_1: '#FAFAFA',
  alabaster_2: '#F7F7F7',
  alto_D7: '#D7D7D7',
  alto_D4: '#D4D4D4',
  anakiwa: '#A0DAFB',
  black: '#000000',
  cerulean: '#0A8ED9',
  c838383: '#838383',
  gray_85: '#858585',
  nandor: '#4D5652',
  transparent: '#00000000',
  paper: '#6200ef',
  white: '#ffffff',
  red: '#FA0E0E',
  shark: '#292D32',
  gable_green: '#152730',
  yellow_orange: '#FBB03B',
  coral: '#FF7755',
  grey_8E: '#8E8E8E',
  alto_D2: '#D2D2D2',
  noble: '#B6B6B6',
  desert_storm: '#f6f6f4',
  tiara: '#cad2d3',
  silver_chalice: '#9e9e9e',
  concrete: '#f2f2f2',
  tundora: '#444444',
  alto_D9: '#D9D9D9',
  blue_dianne: '#213A48',
  jaffa: '#EB9146',
  coral_2: '#FE7755',
  pastel_green: '#53EB95',
  dodger_blue: '#24aaf2',
  dove_gray: '#727070',
  mine_shaft: '#333333',
  green: '#008000',
  mischka: '#DCDFE6',
};

export const rgbaColor = {
  transparent: 'rgba(255,255,255,0)',
  white: 'rgba(255,255,255,1)',
  black_0_24: 'rgba(0, 0, 0, 0.24)',
  white_0_2: 'rgba(255, 255, 255, 0.2)',
  cerulean_0_5: 'rgba(10, 142, 217, 0.5)',
  cerulean_0_2: 'rgba(10, 142, 217, 0.2)',
  cerulean_0_1: 'rgba(10, 142, 217, 0.1)',
  cornflower_blue_0_5: 'rgba(33, 150, 243, 0.5)',
  black_0_1:'rgba(0, 0, 0, 0.1)'
};
