import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/high-res.css';
import {apple, email, google, loginBanner} from '../../assets/images/index';
import {Link} from 'react-router-dom';

const Login: React.FC = () => {
  const [phone, setPhone] = useState('');
  return (
    <div className='container'>
      <div className="max-w-[484px] mx-auto py-6 min-h-screen flex flex-col items-center md:justify-center">
        <div>
          <img className="mb-6 w-full" alt="login-banner" src={loginBanner} />
          <label className="block mb-4 text-xl">
            Enter your mobile number
          </label>
          <div className="phone-select mb-6">
            <PhoneInput
              enableSearch={true}
              country={'nl'}
              value={phone}
              onChange={setPhone}
            />
          </div>
          <button className="btn-primary px-8 mb-6">
            Continue
          </button>
          <div className="text-gray-300 mb-6 relative block w-full text-center before:block before:relative before:w-full before:top-[14px] before:z-0 before:bg-gray-300 before:h-[1px] px-12">
            <span className="px-5 relative bg-white">or</span>
          </div>
          <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center">
            <img alt="google-icon" src={google} />
            Continue with Google
          </button>
          <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center">
            <img alt="google-icon" src={apple} />
            Continue with Apple
          </button>
          <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center">
            <img alt="google-icon" src={email} />
            Continue with Email
          </button>
          <p className="mb-6 text-sm">
            By proceeding, you give Revive and its partners permission to call the
            provided number or send WhatsApp messages or SMS messages (for
            example, via an automated service).
          </p>
          {/* <p className="text-sm">
            By clicking Login, you agree to <Link to="/user-agreement" className="font-semibold underline">User Agreement</Link>
          </p> */}
          <p className="text-sm">
            By clicking Login, you agree to <Link to="/user-agreement" className="font-semibold underline">User Agreement</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
