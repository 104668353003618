import React, {useState} from 'react';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css';
import {PiCaretLeft, PiCaretRight} from 'react-icons/pi';
// import {Link} from 'react-router-dom';
import {otpBanner} from '../../assets/images/index';

const Otp: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [isNextEnable, setIsNextEnable] = useState(false);
  return (
    <div className="max-w-[484px] mx-auto sm:px-0 px-6 py-6 min-h-screen flex flex-col items-center md:justify-center">
      <div>
        <img className="mb-6 w-full" alt="login-banner" src={otpBanner} />
        <div className="flex items-center gap-6 mb-6">
          {/* <Link
            to={'#'}
            className="justify-center hover:text-amber-600 transition ease-in-out duration-300 flex items-center p-0">
            <PiCaretLeft size={20} />
          </Link> */}
          <p className="font-semibold lg:text-2xl">Revive account</p>
        </div>
        <p className="md:text-xl text-base font-medium mb-6">
          Enter the 4-digit code that was sent to you via 06 24247435
        </p>
        <OtpInput
          value={otp}
          onChange={otpVal => {
            setOtp(otpVal);
            setIsNextEnable(otpVal.length === 4);
          }}
          numInputs={4}
          containerStyle={{gap: '24px'}}
          renderInput={(props: any) => (
            <input
              {...props}
              placeholder="0"
              className="p-2 border border-[#D9D9D9] text-gray-950 text-xl font-medium rounded-lg !w-[53px] h-14 flex items-center justify-center bg-[#F0F0F0] placeholder:text-gray-100 focus:border-[#FFBC07] focus-visible:outline-[#FFBC07] shadow-[5px_5px_20px_0px_rgba(0,0,0,0.06)] focus:placeholder-transparent"
            />
          )}
        />
        <button className="btn-gray mt-6 font-medium px-5">
          I did not receive a code
        </button>
      </div>
      <div className="flex items-center gap-3 justify-between w-full pt-[163px]">
        <button className="btn-gray mt-6 font-medium w-[50px] h-[50px] flex items-center justify-center">
          <PiCaretLeft size={20} />
        </button>
        {/* 
          input fill thay ne tyare remove class disabled and remove attribute disabled
        */}
        <button
          disabled={!isNextEnable}
          className={`btn-gray mt-6 font-medium px-5 flex items-center gap-2.5 ${
            isNextEnable ? '' : 'disabled'
          }`}>
          Next <PiCaretRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default Otp;
